export const toggleValueFromString = (string = '', value) => {
  const stringAsArray = string ? string.split('_') : []
  const index = stringAsArray.indexOf(value)
  if (index > -1) {
    stringAsArray.splice(index, 1)
  } else {
    stringAsArray.push(value)
  }
  stringAsArray.sort()
  return stringAsArray.length ? stringAsArray.join('_') : ''
}

export const scrollToElement = (elementId) => {
  const element = typeof document !== 'undefined' ? document.getElementById(elementId)?.offsetTop : null
  const top = element + 10
  if (typeof window !== 'undefined') {
    window.scroll({ top, behavior: 'smooth' })
  }
}

export const capitalize = (string) => {
  if (!string) {
    return string
  }

  const parts = string.split(' ')
  return parts
    .map((s) => s.charAt(0).toUpperCase() + s.toLowerCase().slice(1))
    .join(' ')
}
