import React, { memo, useMemo } from 'react'
import Head from 'next/head'
import { useIntl } from 'react-intl'
import usePageProps from 'hooks/usePageProps'
import Header from 'components/Header'
import Box from '@mui/material/Box'
import dynamic from 'next/dynamic'

const FooterDynamic = dynamic(() => import('components/Footer'))

const Page = ({
  children, sx: sxProp,
}) => {
  const { formatMessage: f } = useIntl()
  const {
    siteName,
    titleMeta,
    descriptionMeta,
    headerProps: headerPropsProp = {},
    footerProps: footerPropsProp = {},
    hideMenu,
    hideFooter,
    mainMenu,
    footerMenu,
    className,
  } = usePageProps()

  const headerProps = useMemo(() => {
    const props = {
      mainMenu,
      position: 'relative',
      logo: '/img/logo.png',
      invertedLogo: '/img/logoInverted.png',
      logoAltText: siteName,
      hideMenu,
      className,
      ...headerPropsProp,
    }
    return props
  }, [mainMenu, siteName, hideMenu, headerPropsProp])

  const menu = footerMenu

  const footerProps = useMemo(() => {
    const props = {
      logo: '/img/logoInverted.png',
      mainMenu,
      menu,
      ...footerPropsProp,
    }
    return props
  }, [mainMenu, footerMenu, footerPropsProp, menu])

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        />
        {titleMeta && (
          <title>
            {f({ id: titleMeta })}
          </title>
        )}

        {descriptionMeta && (
          <meta
            name="description"
            content={f({ id: descriptionMeta })}
          />
        )}
        <meta
          name="robots"
          content="index,follow"
        />

      </Head>
      <Box
        id="root-container"
        sx={{ ...sxProp }}
        className="PageContainer-root"
      >
        <Header {...headerProps} />

        <Box
          id="main-content-container"
          style={{ width: '100%', minHeight: '90vh' }}
        >
          {children}
        </Box>

        {!hideFooter && <FooterDynamic {...footerProps} />}

      </Box>
    </>
  )
}

export default memo(Page)
