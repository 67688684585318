import React, { memo, useEffect, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import Image from 'next/image'
import clsx from 'clsx'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import MenuIcon from '@mui/icons-material/Menu'
import Box from '@mui/material/Box'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ClearIcon from '@mui/icons-material/Clear'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import MuiLink from '@mui/material/Link'
import { FormattedMessage, useIntl } from 'react-intl'
import Sticky from 'react-sticky-el'
import EventEmitter from 'services/EventEmitter'
import CurrentLogo from 'public/img/logo.png'
import LanguageSwitcher from 'components/LanguageSwitcher'
import usePageProps from 'hooks/usePageProps'
import sx from './styles'

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

const Header = (props) => {
  const {
    logo = '',
    logoAltText = '',
    mainMenu = [],
    activeIndex = -1,
    position = 'relative',
    hideMenu = false,
    breadcrumbs = [],
    className: classNameHeader,
  } = props
  const router = useRouter()
  const { formatMessage: f } = useIntl()
  const [inverted, setInverted] = useState(false)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const { locale } = usePageProps()
  const toggleInverted = (val) => setInverted(val)
  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setMobileMenuOpen((prev) => (open === undefined ? !prev : open))
  }

  useEffect(() => {
    setMobileMenuOpen(false)
  }, [router.pathname])

  const handleRouter = () => {
    EventEmitter.emit('logo-click')
    router.push('/')
  }

  const menu = (
    <>
      <Box
        sx={sx.menu}
      >
        {mainMenu.filter((item) => !item.hiddenHeader).map((item, index) => {
          const className = clsx({
            selected: index === activeIndex,
          })

          return (
            <React.Fragment key={`menu-${index}`}>
              <Link
                key={item.id}
                href={`${item.url}`}
                passHref
              >
                <Button
                  key={item.id}
                  sx={sx.menuItem}
                  className={className}
                  href={`${item.url}`}
                  id={item.link_id}
                  title={item.alt}
                  size="small"
                >
                  {locale === 'es' ? item.title : item?.title_en}
                </Button>
              </Link>
            </React.Fragment>
          )
        })}

        <Box ml={4}>
          <LanguageSwitcher />
        </Box>

      </Box>

    </>
  )

  const mobileMenu = (
    <Box sx={sx.mobileMenu}>
      <>
        {!hideMenu && (
          <>
            <LanguageSwitcher />
            <IconButton
              sx={sx.mobileMenuIcon}
              onClick={toggleDrawer()}
              aria-label={f({ id: mobileMenuOpen ? 'HIDE_MOBILE_MENU' : 'SHOW_MOBILE_MENU' })}
            >
              <MenuIcon sx={sx.magentaIcon} />
            </IconButton>
            <SwipeableDrawer
              anchor="right"
              open={mobileMenuOpen}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
              sx={sx.drawer}
            >
              <DrawerHeader sx={sx.drawerHeader}>
                <Box
                  sx={{
                    paddingTop: 1,
                    cursor: 'pointer',
                    display: 'block',
                  }}
                  onClick={handleRouter}
                >
                  <Image
                    src={CurrentLogo}
                    alt={logoAltText}
                    width={100}
                    height={50}
                    priority
                  />
                </Box>
                <Box>
                  <IconButton
                    onClick={toggleDrawer(false)}
                    aria-label={f({ id: 'CLOSE' })}
                  >
                    <ClearIcon sx={{ ...sx.blueIcon, ...sx.clearIcon }} />
                  </IconButton>
                </Box>
              </DrawerHeader>
              <List>
                {mainMenu.filter((item) => !item.hiddenHeader).map((item, index) => (
                  <Link
                    key={item.id}
                    href={item.url}
                    passHref
                  >
                    <ListItem
                      sx={sx.mobileMenuItem}
                      className={clsx({
                        selected: index === activeIndex,
                      })}
                      component="a"
                      onClick={toggleDrawer(false)}
                      divider
                    >
                      <ListItemText primary={locale === 'es' ? item.title : item?.title_en} />
                    </ListItem>
                  </Link>
                ))}
              </List>

            </SwipeableDrawer>
          </>
        )}
      </>
    </Box>
  )

  return (
    <Sticky
      onFixedToggle={toggleInverted}
      topOffset={inverted ? 4 : 0}
      className="wrapper-stycky-header"
    >
      <AppBar
        position={position}
        elevation={inverted ? 2 : 0}
        sx={sx.appbar}
        className={clsx(classNameHeader, { scrolled: inverted })}
      >
        <Toolbar disableGutters>
          <Box sx={sx.logoContainer}>

            <Box
              sx={{
                paddingTop: 1,
                cursor: 'pointer',
                display: 'block',
              }}
              onClick={handleRouter}
            >
              {typeof logo === 'string' ? (
                <Image
                  src={CurrentLogo}
                  alt={logoAltText}
                  width={100}
                  height={50}
                  priority
                />
              ) : (
                <CurrentLogo />
              )}
            </Box>

            {breadcrumbs && breadcrumbs.length > 0 && (
              <Breadcrumbs
                sx={sx.breadcrumbs}
                aria-label="breadcrumb"
              >
                {breadcrumbs.map((item, i) => (
                  <Link
                    key={i}
                    href={f({ id: item.href.id }, item.href.values)}
                    passHref
                  >
                    <MuiLink color="inherit">
                      <FormattedMessage
                        id={item.label.id}
                        values={item.label.values}
                      />
                    </MuiLink>
                  </Link>
                ))}
              </Breadcrumbs>
            )}
          </Box>
          {!hideMenu && (menu)}
          {mobileMenu}
        </Toolbar>
      </AppBar>
    </Sticky>
  )
}

export default memo(Header)
