import React, { memo, useState } from 'react'
import { useRouter } from 'next/router'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { capitalize } from 'utils/universal'

const LanguageSwitcher = () => {
  const router = useRouter()
  const { locale, locales } = router
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    const value = event?.currentTarget
    setAnchorEl(value)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const selectLang = (localeSeleted) => {
    setAnchorEl()
    if (localeSeleted) {
      const path = router.asPath
      router.push(path, path, { locale: localeSeleted })
    }
  }

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      >
        {capitalize(locale)}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        {locales?.filter((i) => i !== locale)?.map((lang) => (
          <MenuItem
            key={`lang-items-${lang}`}
            onClick={() => selectLang(lang)}
          >
            {capitalize(lang)}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default memo(LanguageSwitcher)
